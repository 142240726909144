










import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Helpers from '../modules/Helpers';

@Component
export default class QrScanView extends Vue {
  @Prop(Boolean) readonly open: boolean | undefined
  
  hasCamera               = false;
  isQrScannerLoading      = true;
  qrScannerCamera         = 'off';
  qrScannerError?: string = '';

  @Watch('open')
  toggleQrScanner(val: boolean, oldVal: boolean) {
    this.qrScannerCamera    = val ? 'auto' : 'off';
    this.isQrScannerLoading = true;
    this.qrScannerError     = this.hasCamera ? '' : this.$t('Could not initialize camera. Unsupported browser or no camera present on the device.');
  }

  onQrScannerDecode(result: string) {
      this.$emit('decode', result);
  }

  async onQrScannerInit(promise: Promise<any>) {
      try {
        await promise;
      } catch (error) {
        this.qrScannerError = `${this.$t('Could not initialize camera.')} `;
        
        switch(error.name) {
          case 'NotAllowedError':
            this.qrScannerError += this.$t('You need to grant camera access permisson.')!;
            break;
          case 'NotFoundError':
            this.qrScannerError += this.$t('No camera present on the device.')!;
            break;
          case 'NotSupportedError':
            this.qrScannerError += this.$t('Secure context required (HTTPS, localhost).')!;
            break;
          case 'NotReadableError':
            this.qrScannerError += this.$t('Camera already in use.')!;
            break;
          case 'OverconstrainedError':
            this.qrScannerError += this.$t('Installed cameras are not suitable for use with QR scanner.')!;
            break;
          case 'StreamApiNotSupportedError':
            this.qrScannerError += this.$t('Unsupported browser, please consider upgrading to the latest version.')!;
            break;
        }

        this.qrScannerError = this.qrScannerError.trim();
      }

      this.isQrScannerLoading = false;
  }

  async mounted() {
    this.hasCamera = await Helpers.hasCamera();
  }
}
